import React, { useEffect, useState } from "react";
import { Col, Divider, message, Modal, Row } from "antd";
import InputWithoutIconMedium from "../../../Biblio/Inputs/InputWithoutIconMedium";
import ButtonSecondary from "../../../Biblio/Buttons/ButtonSecondary";
import "../../../styles/modalAdd.scss";
import InputWithoutIconExtralarge from "../../../Biblio/Inputs/InputWithoutIconExtralarge";
import checked from "../../../assets/icons/checked.svg";
import SelectLarge from "../../../Biblio/Selects/SelectLarge";
import { options, options2, options3, sexe } from "../../../data/data";
import SelectSmall from "../../../Biblio/Selects/SelectSmall";
import InputWithoutIconNormal from "../../../Biblio/Inputs/InputWithoutIconNormal";
import InputWithoutIconSmall from "../../../Biblio/Inputs/InputWithoutIconSmall";
import InputWithoutIconMediumLarge from "../../../Biblio/Inputs/InputWithoutIconMediumLarge";
import InputWithoutIconLarge from "../../../Biblio/Inputs/InputWithoutIconLarge";
import InputWithoutIconMediumSmall from "../../../Biblio/Inputs/InputWithoutIconMediumSmall";
import SelectMedium from "../../../Biblio/Selects/SelectMedium";
import StepsComponent from "../../../Biblio/Steps";
import ButtonPrimary from "../../../Biblio/Buttons/ButtonPrimary";
import { CaseCdisInterface } from "../../../interfaces/case.interface";
import { addCasesAsCdisAgent } from "../../../apis/actions/case.actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../apis/store";
import * as Yup from "yup";
interface ModalProps {
  isVisible: boolean;
  onCancel: () => void;
  onComplete?: () => void;
  errors: any;
  setErrors: any;
}
interface Step {
  title: JSX.Element;
  content: JSX.Element;
  disabled?: boolean;
}
const validationSchema = Yup.object({
  subject: Yup.string().required("يرجى اختيار موضوع الإحالة"),
  fullNameConcerned: Yup.string().required("يرجى إدخال الاسم واللقب"),
  gender: Yup.string().required("يرجى اختيار الجنس"),
  birthday: Yup.date()
    .required("يرجى إدخال تاريخ الميلاد")
    .typeError("التاريخ غير صالح"),
  city: Yup.string().required("يرجى إدخال المدينة"),
  address: Yup.string().required("يرجى إدخال العنوان"),
  phoneConcerned: Yup.number()
    .test(
      "is-required",
      "يرجى إدخال رقم الهاتف",
      (value) => value !== undefined && value !== 0
    )
    .test(
      "is-valid-number",
      "رقم الهاتف غير صحيح",
      (value) =>
        !value ||
        (!isNaN(Number(value)) &&
          Number(value) > 0 &&
          Number.isInteger(Number(value)))
    )
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),

  CIN: Yup.number()
    .test(
      "is-required",
      "يرجى إدخال عدد ب ت و",
      (value) => value !== undefined && value !== 0
    )
    .test(
      "is-valid-number",
      "  عدد ب ت و يجب أن يكون رقماً صحيحاً",
      (value) =>
        !value ||
        (!isNaN(Number(value)) &&
          Number(value) > 0 &&
          Number.isInteger(Number(value)))
    )
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  socialSituation: Yup.string().required("يرجى إدخال الحالة المدنية"),
  levelScolaire: Yup.string().required("يرجى إدخال المستوى التعليمي"),
  profession: Yup.string().required("يرجى إدخال المهنة"),
  socialCoverage: Yup.string().required("يرجى اختيار التغطية الاجتماعية"),
  medicalCoverage: Yup.string().required("يرجى اختيار التغطية الصحية"),
});
const ModalAdd = ({ errors, setErrors, ...props }: ModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [current, setCurrent] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [cases, setCases] = useState<CaseCdisInterface>({
    subject: "",
    fullNameConcerned: "",
    gender: "",
    birthday: "",
    city: "",
    address: "",
    phoneConcerned: 0,
    CIN: 0,
    socialSituation: "",
    levelScolaire: "",
    profession: "",
    socialCoverage: "",
    medicalCoverage: "",
  });

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(cases, { abortEarly: false });
      const result = await dispatch(addCasesAsCdisAgent(cases)).then(
        (result) => {
          setIsLoading(false);
          return result;
        }
      );

      if (result.meta.requestStatus === "fulfilled") {
        props.onCancel();

        message.success("تم اضافة الإحالة بنجاح");
        setCases({
          subject: "",
          fullNameConcerned: "",
          gender: "",
          birthday: "",
          city: "",
          address: "",
          phoneConcerned: 0,
          CIN: 0,
          socialSituation: "",
          levelScolaire: "",
          profession: "",
          socialCoverage: "",
          medicalCoverage: "",
        });
        setCurrent(0);
        props.onComplete && props.onComplete();
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors: any = {};
        error.inner.forEach((err) => {
          if (err.path) {
            validationErrors[err.path] = err.message;
          }
        });
        setErrors(validationErrors);
      }
    }
  };
  const handleValidation = () => {
    if (!cases.subject) {
      setTimeout(() => {
        setIsLoading(false);
        message.error("يرجى تعبئة موضوع الإحالة");
      }, 1000);
    } else {
      next();
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [errors]);

  //handle validate

  const steps: Step[] = [
    {
      title: (
        <div className="cdis--step-title">
          <span className="cdis--step-title-number">1</span>
          <span>موضوع الإحالة</span>
          {current > 0 && (
            <img className="cdis--img-step" src={checked} alt="checked" />
          )}
        </div>
      ),
      content: (
        <>
          <div className="cdis--step-form">
            <SelectLarge
              onChange={(e) => setCases({ ...cases, subject: e.target.value })}
              selectPlaceholder="أختر موضوع الإحالة"
              options={options}
              value={cases.subject || ""}
            />
          </div>
        </>
      ),
    },
    {
      title: (
        <div className="cdis--step-title">
          <span className="cdis--step-title-number">2</span>
          <span>المعني</span>
          {current > 1 && (
            <img className="cdis--img-step" src={checked} alt="checked" />
          )}
        </div>
      ),
      content: (
        <>
          <Row
            style={{ direction: "rtl" }}
            justify={"space-between"}
            gutter={48}
          >
            <Col
              xxl={10}
              xl={10}
              md={12}
              lg={12}
              xs={24}
              className="cdis--mt-3"
            >
              <InputWithoutIconExtralarge
                type="text"
                placeholder="الاسم واللقب"
                value={cases.fullNameConcerned || ""}
                onChange={(e) =>
                  setCases({ ...cases, fullNameConcerned: e.target.value })
                }
              />
              {errors.fullNameConcerned && (
                <span style={{ color: "red" }}>{errors.fullNameConcerned}</span>
              )}{" "}
            </Col>
            <Col xxl={5} xl={5} md={12} lg={12} xs={24} className="cdis--mt-3">
              <SelectSmall
                selectPlaceholder="اختر الجنس"
                options={sexe}
                value={cases.gender || ""}
                onChange={(e) => setCases({ ...cases, gender: e.target.value })}
              />
              {errors.gender && (
                <span style={{ color: "red" }}>{errors.gender}</span>
              )}
            </Col>
            <Col xxl={5} xl={5} md={12} lg={12} xs={24} className="cdis--mt-3">
              <InputWithoutIconNormal
                type="date"
                placeholder="18-09-1999"
                form="DD-MM-YYYY"
                value={cases.birthday || ""}
                onChange={(e) =>
                  setCases({ ...cases, birthday: e.target.value })
                }
              />
              {errors.birthday && (
                <span style={{ color: "red" }}>{errors.birthday}</span>
              )}
            </Col>
            <Col xxl={4} xl={4} md={12} lg={12} xs={24} className="cdis--mt-3">
              <InputWithoutIconSmall
                type="text"
                placeholder="قابس"
                value={cases.city || ""}
                onChange={(e) => setCases({ ...cases, city: e.target.value })}
              />
              {errors.city && (
                <span style={{ color: "red" }}>{errors.city}</span>
              )}
            </Col>
          </Row>
          {/* ------------------ */}
          <Row
            style={{ direction: "rtl" }}
            justify={"space-between"}
            gutter={48}
          >
            <Col xxl={6} xl={6} md={12} lg={12} xs={24} className="cdis--mt-3">
              <InputWithoutIconExtralarge
                type="text"
                placeholder="العنوان"
                value={cases.address || ""}
                onChange={(e) =>
                  setCases({ ...cases, address: e.target.value })
                }
              />
              {errors.address && (
                <span style={{ color: "red" }}>{errors.address}</span>
              )}
            </Col>
            <Col xxl={6} xl={6} md={12} lg={12} xs={24} className="cdis--mt-3">
              <InputWithoutIconMediumLarge
                type="text"
                placeholder="رقم الهاتف"
                value={cases.phoneConcerned || ""}
                onChange={(e) =>
                  setCases({ ...cases, phoneConcerned: Number(e.target.value) })
                }
              />
              {errors.phoneConcerned && (
                <span style={{ color: "red" }}>{errors.phoneConcerned}</span>
              )}
            </Col>
            <Col xxl={6} xl={6} md={12} lg={12} xs={24} className="cdis--mt-3">
              <InputWithoutIconLarge
                type="text"
                placeholder="عدد ب ت و"
                value={cases.CIN || ""}
                onChange={(e) =>
                  setCases({ ...cases, CIN: Number(e.target.value) })
                }
              />
              {errors.CIN && <span style={{ color: "red" }}>{errors.CIN}</span>}
            </Col>
            <Col
              xxl={6}
              xl={6}
              md={12}
              lg={12}
              xs={24}
              className="cdis--mt-3"
              style={{ direction: "rtl" }}
            >
              <SelectMedium
                selectPlaceholder="التغطية الصحية "
                options={options3}
                value={cases.medicalCoverage || ""}
                onChange={(e) =>
                  setCases({ ...cases, medicalCoverage: e.target.value })
                }
              />
              {errors.medicalCoverage && (
                <span style={{ color: "red" }}>{errors.medicalCoverage}</span>
              )}
            </Col>
          </Row>
          {/* ------------------ */}
          <Row
            style={{ direction: "rtl" }}
            justify={"space-between"}
            gutter={48}
          >
            <Col xxl={5} xl={5} md={12} lg={12} xs={24} className="cdis--mt-3">
              <InputWithoutIconMediumSmall
                type="text"
                placeholder="الحالة المدنية"
                value={cases.socialSituation || ""}
                onChange={(e) =>
                  setCases({ ...cases, socialSituation: e.target.value })
                }
              />
              {errors.socialSituation && (
                <span style={{ color: "red" }}>{errors.socialSituation}</span>
              )}
            </Col>
            <Col xxl={5} xl={5} md={12} lg={12} xs={24} className="cdis--mt-3">
              <InputWithoutIconMedium
                type="text"
                placeholder="المستوى التعليمي"
                value={cases.levelScolaire || ""}
                onChange={(e) =>
                  setCases({ ...cases, levelScolaire: e.target.value })
                }
              />
              {errors.levelScolaire && (
                <span style={{ color: "red" }}>{errors.levelScolaire}</span>
              )}
            </Col>
            <Col xxl={7} xl={7} md={12} lg={12} xs={24} className="cdis--mt-3">
              <InputWithoutIconMedium
                type="text"
                placeholder="المهنة"
                value={cases.profession || ""}
                onChange={(e) =>
                  setCases({ ...cases, profession: e.target.value })
                }
              />
              {errors.profession && (
                <span style={{ color: "red" }}>{errors.profession}</span>
              )}
            </Col>
            <Col xxl={7} xl={7} md={12} lg={12} xs={24} className="cdis--mt-3">
              <SelectMedium
                selectPlaceholder="التغطية الإجتماعية"
                options={options2}
                value={cases.socialCoverage || ""}
                onChange={(e) =>
                  setCases({ ...cases, socialCoverage: e.target.value })
                }
              />
              {errors.socialCoverage && (
                <span style={{ color: "red" }}>{errors.socialCoverage}</span>
              )}
            </Col>
          </Row>
        </>
      ),
      disabled: current < 1,
    },
  ];

  const next: () => void = () => {
    setIsLoading(false);
    setCurrent(current + 1);
  };

  const prev: () => void = () => {
    setCurrent(current - 1);
  };
  return (
    <div className="cdis--modal">
      <Modal
        className="modal-add-person"
        open={props.isVisible}
        footer={null}
        onCancel={props.onCancel}
      >
        <div className="cdis--modal-edit-opportunity-container ">
          <h1>إضافة حالة</h1>
          <Divider className="cdis--divider" />
          <Row gutter={24} className="cdis--form-inscrit-body">
            <div className="cdis--form-inscrit-step">
              <StepsComponent current={current} steps={steps} />
              <div className="cdis--steps-content">
                {steps[current].content}
              </div>
            </div>
            <div className="cdis--form-inscrit-button">
              {current < steps.length - 1 ? (
                <ButtonSecondary
                  title="التالي"
                  onClick={() => {
                    setIsLoading(true);
                    handleValidation();
                  }}
                  loading={isLoading}
                />
              ) : (
                <ButtonSecondary
                  title="التالي"
                  onClick={() => {
                    setIsLoading(true);
                    handleSubmit();
                  }}
                  loading={isLoading}
                />
              )}
              <ButtonPrimary
                title="السابق"
                onClick={() => {
                  prev();
                }}
                disabled={current === 0}
              />
            </div>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAdd;
