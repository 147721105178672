import { createAsyncThunk } from "@reduxjs/toolkit";
import { Candidate } from "../../interfaces/candidate.types";
import axios from "axios";
import { message } from "antd";
import api from "../utils/interceptor-expire-token";
interface getAllCandidates {
  id: string;
  page: number;
}

//ADD CANDIDATE
export const addCandidate = createAsyncThunk(
  "candidates/addCandidate",
  async (candidate: Candidate) => {
    try {
      const response = await api.post(`candidate/add-candidate`, candidate);
      message.success("تمت إضافة المرشح بنجاح");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || "An error occurred");
      } else {
        message.error("An unexpected error occurred");
      }
      console.log(error);
      throw error;
    }
  }
);
//
export const getAllCandidateByOpportunityId = createAsyncThunk(
  "candidates/getAllCandidateByOpportunityId",
  async ({ id, page }: getAllCandidates) => {
    try {
      const response = await api.get(
        `candidate/get-all-candidates-by-opportunity-id?opportunityId=${id}&page=${page}`
      );
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || "An error occurred");
      } else {
        message.error("An unexpected error occurred");
      }
      console.log(error);
      throw error;
    }
  }
);
