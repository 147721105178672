import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import api from "../utils/interceptor-expire-token";

// get all cases opportunity
export const getAllCasesOpportunity = createAsyncThunk<AxiosResponse<any, any>>(
  "cases-opportunity/getAll",
  async () => {
    try {
      let response = await api.get(
        `dashbaord/all-opportunities-cases-dashboard`
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

// get statistcs cases
export const getStatistcsCases = createAsyncThunk<AxiosResponse<any, any>>(
  "cases-statistcs/getAll",
  async () => {
    try {
      let response = await api.get(`dashbaord/all-cases-dashboard`);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

// get statistcs cases
export const getOpportunityByType = createAsyncThunk<AxiosResponse<any, any>>(
  "opportunity-statistcs/getAll",
  async () => {
    try {
      let response = await api.get(`dashbaord/all-opportunities-dashboard`);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);
