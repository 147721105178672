import React, { useState } from "react";
import type { MenuProps } from "antd";
import { Layout, Menu, message, theme } from "antd";
import "../../styles/sideBar.scss";
import opportunityIcon from "../../assets/icons/oportunity.svg";
import addIcon from "../../assets/icons/add.svg";
import newsIcon from "../../assets/icons/news.svg";
import inboxIcon from "../../assets/icons/acceuil.svg";
import teamIcon from "../../assets/icons/team.svg";
import ButtonFaded from "../Buttons/ButtonFaded";
import Perspectives from "../../domains/dashboard/Perspectives";
import Opportunities from "../../domains/dashboard/Opportunities";
import ModalAdd from "../../domains/dashboard/modals/ModalAdd";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../apis/store";
import { logoutUser } from "../../apis/actions/auth.actions";
import DashboardHome from "../../domains/dashboard/DashboardHome";
import News from "../../domains/dashboard/News";
import PerspectivesFile from "../../domains/dashboard/PerspectivesFile";
interface SideBarProps extends React.HTMLAttributes<HTMLDivElement> {
  sideBarTitle: string;
  menuList: string[];
  urlLogo: string;
  urlLogoTitle: string;
  headerTitle: string;
  headTitle: string;
  buttonTitle: string;
}
const { Header, Content, Sider } = Layout;

const SideBar: React.FC<SideBarProps> = ({ menuList, ...props }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const handleLogout = () => {
    try {
      dispatch(logoutUser()).then(() => {
        setLoading(false);
      });
      window.location.replace("/login");
      message.success("تم تسجيل الخروج بنجاح");
    } catch (error) {
      message.error("حدث خطأ اثناء تسجيل الخروج");
    }
  };

  const items: MenuProps["items"] = [
    inboxIcon,
    teamIcon,
    opportunityIcon,
    newsIcon,
    addIcon,
    teamIcon,
  ].map((icon, index) => ({
    key: String(index + 1),
    icon: <img alt="" src={icon} />,
    label: menuList[index],
  }));
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [selectedKey, setSelectedKey] = useState("1");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleMenuClick = (e: { key: string }) => {
    setSelectedKey(e.key);
    if (e.key === "5") {
      setIsModalVisible(true);
    }
  };
  const [errors, setErrors] = useState<any>({});
  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedKey("2");
    setErrors({});
  };

  const handleAddComplete = () => {
    setIsModalVisible(false);
    setSelectedKey("2");
  };
  const renderContent = () => {
    switch (selectedKey) {
      case "1":
        return <DashboardHome />;
      case "2":
        return <Perspectives />;
      case "3":
        return <Opportunities />;
      case "4":
        return <News />;
      case "6":
        return <PerspectivesFile />;
      case "7":
        return null;
    }
  };
  //
  return (
    <Layout {...props} hasSider className="cdis--side-bar-container">
      <Sider
        style={{
          overflow: "auto",
          height: "95vh",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
        }}
        className="cdis--side-bar-content"
      >
        <div className="demo-logo-vertical"></div>
        <h6 className="cdis--sidebar-title">{props.sideBarTitle}</h6>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          selectedKeys={[selectedKey]}
          onClick={handleMenuClick}
          items={items}
        />
        <ButtonFaded
          className="cdis--sidebar-logout"
          title={props.buttonTitle}
          onClick={() => {
            setLoading(true);
            handleLogout();
          }}
          loading={loading}
        />
      </Sider>

      <Layout className="cdis--layout">
        <Header
          className="cdis--sideBar"
          style={{ padding: 0, background: colorBgContainer }}
        >
          <div className="cdis-logo-title-container">
            <img
              width="20%"
              alt="logo"
              className="cursor-pointer"
              src={props.urlLogoTitle}
              onClick={() => {
                setSelectedKey("1");
              }}
            />
            <img
              width="40%"
              alt="logo"
              className="cursor-pointer"
              src={props.urlLogo}
              onClick={() => {
                setSelectedKey("1");
              }}
            />
          </div>
          <div className="cdis-logo-title cursor-pointer">
            <h6 className="cdis--header-first-title">{props.headerTitle} </h6>
            <h5 className="cdis--header-second-title">{props.headTitle} </h5>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 316px 0 0",
            overflow: "initial",
            textAlign: "center",
          }}
        >
          {renderContent()}
        </Content>
      </Layout>
      <ModalAdd
        errors={errors}
        setErrors={setErrors}
        isVisible={isModalVisible}
        onCancel={handleModalCancel}
        onComplete={handleAddComplete}
      />
    </Layout>
  );
};

export default SideBar;
