import React from "react";
import { Route, Routes } from "react-router-dom";
import Biblio from "./components/Biblio";
import Login from "./pages/Login/Login";
import FormInscrit from "./pages/FormInscrit/FormInscrit";
import SideBar from "./Biblio/SideBar/SideBar";
import logo from "./assets/logos/cedis-xsmall.svg";
import miniLogo from "./assets/logos/ministère.svg";
import Home from "./domains/pages/Home";
import ProtectedRoute from "./protectedRoute/protectedRoute";
import NewDetails from "./components/section/NewDetails";
import HomePage from "./pages/FormInscrit/HomePage";
const list = [
  "الرئيسية",
  "المنظورين",
  "الفرص",
  "المستجدات",
  "إضافة حالة",
  "الملفات",
];

function App() {
  return (
    <div className="cdis--app-container">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/biblio" element={<Biblio />} />
        <Route path="/login" element={<Login />} />
        <Route path="/add-case" element={<HomePage />} />
        <Route element={<ProtectedRoute />}>
          <Route
            path="/sidebar"
            element={
              <SideBar
                headerTitle="الجمهورية التونسية"
                headTitle="وزارة الشؤون الإجتماعية"
                urlLogo={logo}
                urlLogoTitle={miniLogo}
                menuList={list}
                sideBarTitle="مركز الدفاع والادماج الاجتماعي بقـــــــــــــــــابس"
                buttonTitle="الخروج"
              />
            }
          />
        </Route>
        <Route path="/news/:id" element={<NewDetails />} />
      </Routes>
    </div>
  );
}

export default App;
