import React from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";

const center = {
  lat: 33.8831, 
  lng: 10.0988, 
};


const mapContainerStyle = {
  height: "400px",
  width: "100%",
};

// Map options
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

const MapComponent = () => {
  return (
    <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={15}
        options={options}
      />
    </LoadScript>
  );
};

export default MapComponent;
