import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { UserLogin } from "../../pages/Login/Login";
import api from "../utils/interceptor-expire-token";

// Login
export const loginUser = createAsyncThunk<AxiosResponse<any, any>, UserLogin>(
  "login/auth",
  async (userLogin: UserLogin) => {
    try {
      const config = {
        headers: { "Content-Type": "application/json" },
      };

      let response = await api.post(`auth/login`, userLogin, config);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// Check user
export const checkUserConnection = createAsyncThunk<AxiosResponse<any, any>>(
  "auth/checkUserConnection",
  async (_) => {
    try {
      const response = await api.get(`auth/current`);
      return response.data.success;
    } catch (error) {
      throw error;
    }
  }
);

// Logout
export const logoutUser = createAsyncThunk<AxiosResponse<any, any>>(
  "auth/logout",
  async () => {
    try {
      const response = await api.post(`auth/logout`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
