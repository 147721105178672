import { Table } from "antd";
import React, { ChangeEvent, useEffect, useState } from "react";
import PaginationTable from "../../Biblio/PaginationTable";
import "../../styles/perspectivesfile.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllCases, getAllCasesImage } from "../../apis/actions/case.actions";
import { AppDispatch } from "../../apis/store";
import ButtonPrimary from "../../Biblio/Buttons/ButtonPrimary";
import { SectionLoader } from "../../Biblio/loaders/SectionLoader";

const PerspectivesFile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<
    ChangeEvent<HTMLSelectElement> | string
  >("");
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const data = useSelector((state: any) => state?.cases?.casesImage?.data?.success);


  const totalPages = useSelector(
    (state: any) => state?.cases?.casesImage?.data?.totalCases
  );
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    dispatch(
      getAllCasesImage({
        page: currentPage,
        search: searchTerm,
        selectSearch: selectedOption,
      })
    ).then(() => setLoading(false));
  }, [dispatch, currentPage, searchTerm, selectedOption]);

  const openBase64 = (data: string) => {
    if (data.includes("jpg") || data.includes("jpeg")) {
      const w = window.open("");
      if (w) {
        w.document.write(
          `<embed width="50%" height="50%" src="${data}" type="image/jpeg" />`
        );
      } else {
        console.error("Failed to open a new tab for image.");
      }
    } else if (data.includes("png")) {
      const w = window.open("");
      if (w) {
        w.document.write(
          `<embed width="100%" height="100%" src="${data}" type="image/png" />`
        );
      } else {
        console.error("Failed to open a new tab for image.");
      }
    } else {
      const w = window.open("about:blank");
      if (w) {
        const image = new Image();
        image.src = data;
        setTimeout(() => {
          const body = w.document.getElementsByTagName("body")[0];
          if (body) {
            body.innerHTML = image.outerHTML;
          }
        }, 0);
      } else {
        console.error("Failed to open a new tab for image.");
      }
    }
  };

  const dataSource =
    data &&
    data.map((item: any, index: number) => ({
      key: index.toString(),
      name: item?.source?.fullNameSource,
      phone: item?.source?.phoneSource,
      statut: item?.source?.status,
      email: item?.source?.email,
      document: item.image,
    }));

  const columns = [
    {
      title: "الاسم واللقب",
      dataIndex: "name",
      key: "name",
      width: "15%",
    },
    {
      title: "الصفة",
      dataIndex: "statut",
      key: "statut",
      width: "15%",
    },
    {
      title: "رقم الهاتف",
      dataIndex: "phone",
      key: "phone",
      width: "15%",
    },
    {
      title: "البريد الإلكتروني",
      dataIndex: "email",
      key: "email",
      width: "15%",
    },
    {
      title: "ملف",
      dataIndex: "document",
      key: "document",
      width: "15%",
      render: (text: any, record: any) => {
        return (
          <ButtonPrimary
            className="cdis--btn-perspective"
            title="مشاهدة"
            onClick={() => openBase64(record.document)}
          />
        );
      },
    },
  ];

  return (
    <div
      className={
        loading
          ? "cdis--loader-prespective"
          : "cdis--perspectives-file-container"
      }
    >
      <div className="cdis--perspectives-header">
        <h1 className="cdis--perspectives-title">الملفات</h1>
      </div>
      {loading ? (
        <SectionLoader />
      ) : (
        <>
          <div className="cdis--perspectives-file-table">
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </div>
          <PaginationTable
            defaultCurrent={1}
            current={currentPage}
            total={totalPages}
            onChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
};

export default PerspectivesFile;
