export const options = [
  "اسرة مفككة",
  "طفل مهدد",
  "طفل منقطع عن التعليم",
  "طفل في نزاع مع القانون",
  "طفل في الشارع",
  "مسرح من السجن",
  "مسرح من الإصلاحية",
  "امرأة معنفة",
  "تشغيل اطفال",
  "حالة ادمان",
  "وضعية اخرى.",
];

export const options2 = [
  "الصندوق الوطني للضمان الاجتماعي",
  "الصندوق الوطني للتقاعد و الحيطة الاجتماعية",
];

export const options3 = [
  "الصندوق الوطني للتأمين على المرض",
  "علاج مجاني",
  "علاج بالتعريفة المنخفضة",
];

export const sexe = ["ذكر", "أنثى"];

//Navbar items
export const sections = [
  { id: "accueil", label: "الرئيسية" },
  { id: "about", label: "من نحن" },
  { id: "opportunity", label: "الفرص" },
  { id: "news", label: "مستجدات" },
  { id: "partners", label: "شركائنا" },
];
