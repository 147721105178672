// src/api/api.ts
import axios from "axios";
import { baseUrlApi } from "../../proxy";

// Create an Axios instance
const api = axios.create({
  baseURL: baseUrlApi,
  withCredentials: true,
});

// Add an interceptor to handle 401 responses
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default api;
