import React from "react";
import "../../styles/buttons.scss";
import ButtonLoader from "../loaders/ButtonLoader";
interface ButtonOutlinedProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  title: string;
  loading?: boolean;
  property?:string
}

const ButtonOutlined = ({ title, onClick,loading, ...props }: ButtonOutlinedProps) => {
  console.log(props.property, "loading clasName");
 
  return (
    <button
      {...props}
      className={`cdis--button-outlined ${props.className ?? ""}`}
      onClick={onClick}
    >
      {title}
      {loading ? <ButtonLoader className={props.property} />: <div style={{ width: '20px', padding: '8px' }} />}
    </button>
  );
};

export default ButtonOutlined;
