import React, { useEffect, useState } from "react";
import "../../styles/formInscrit.scss";
import logo from "../../assets/logos/cedis-xsmall.svg";
import StepsComponent from "../../Biblio/Steps";
import ButtonPrimary from "../../Biblio/Buttons/ButtonPrimary";
import ButtonSecondary from "../../Biblio/Buttons/ButtonSecondary";
import checked from "../../assets/icons/checked.svg";
import images from "../../assets/images/partner-3.svg";
import InputWithoutIconExtralarge from "../../Biblio/Inputs/InputWithoutIconExtralarge";
import SelectLarge from "../../Biblio/Selects/SelectLarge";
import { options, options2, options3, sexe } from "../../data/data";
import SelectSmall from "../../Biblio/Selects/SelectSmall";
import InputWithoutIconNormal from "../../Biblio/Inputs/InputWithoutIconNormal";
import InputWithoutIconSmall from "../../Biblio/Inputs/InputWithoutIconSmall";
import InputWithoutIconMediumLarge from "../../Biblio/Inputs/InputWithoutIconMediumLarge";
import InputWithoutIconLarge from "../../Biblio/Inputs/InputWithoutIconLarge";
import InputWithoutIconMediumSmall from "../../Biblio/Inputs/InputWithoutIconMediumSmall";
import InputWithoutIconMedium from "../../Biblio/Inputs/InputWithoutIconMedium";
import SelectMedium from "../../Biblio/Selects/SelectMedium";
import { Col, Row } from "antd";
import { CaseInterface } from "../../interfaces/case.interface";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../apis/store";
import { addCases } from "../../apis/actions/case.actions";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FirstStep from "./FirstStep";
interface Step {
  title: JSX.Element;
  content: JSX.Element;
  disabled?: boolean;
}

interface Props {
  selectedComponent: string;
}

const FormInscrit = ({ selectedComponent }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [current, setCurrent] = useState<number>(0);
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);
  const [cases, setCases] = useState<CaseInterface>({
    fullNameSource: "",
    status: "",
    email: "",
    phoneSource: 0,
    subject: "",
    fullNameConcerned: "",
    gender: "",
    birthday: null,
    city: "",
    address: "",
    phoneConcerned: 0,
    CIN: 0,
    socialSituation: "",
    levelScolaire: "",
    profession: "",
    socialCoverage: "",
    medicalCoverage: "",
    image: null,
  });

  const [errors, setErrors] = useState<any>({});
  // State to control the loading
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = Yup.object({
    fullNameSource: Yup.string().required("يرجى إدخال الاسم واللقب"),
    status: Yup.string().required("يرجى إدخال الصفة"),
    phoneSource: Yup.number()
      .test(
        "is-required",
        "يرجى إدخال رقم الهاتف",
        (value: any) => value !== undefined && value !== 0
      )
      .test(
        "is-valid-number",
        "رقم الهاتف غير صحيح",
        (value: any) =>
          !value ||
          (!isNaN(Number(value)) &&
            Number(value) > 0 &&
            Number.isInteger(Number(value)))
      )
      .transform((value: any) => (isNaN(value) ? undefined : value))
      .nullable(),
    subject: Yup.string().required("يرجى اختيار موضوع الإحالة"),
    fullNameConcerned: Yup.string().required("يرجى إدخال الاسم واللقب"),
    gender: Yup.string().required("يرجى اختيار الجنس"),
    birthday: Yup.date()
      .required("يرجى إدخال تاريخ الميلاد")
      .typeError(" يرجى إدخال التاريخ "),
    city: Yup.string().required("يرجى إدخال المدينة"),
    address: Yup.string().required("يرجى إدخال العنوان"),
    phoneConcerned: Yup.number()
      .test(
        "is-required",
        "يرجى إدخال رقم الهاتف",
        (value: any) => value !== undefined && value !== 0
      )
      .test(
        "is-valid-number",
        "رقم الهاتف غير صحيح",
        (value: any) =>
          !value ||
          (!isNaN(Number(value)) &&
            Number(value) > 0 &&
            Number.isInteger(Number(value)))
      )
      .transform((value: any) => (isNaN(value) ? undefined : value))
      .nullable(),

    CIN: Yup.number()
      .test(
        "is-required",
        "يرجى إدخال عدد ب ت و",
        (value: any) => value !== undefined && value !== 0
      )
      .test(
        "is-valid-number",
        "عدد ب ت و غير صحيح",
        (value: any) =>
          !value ||
          (!isNaN(Number(value)) &&
            Number(value) > 0 &&
            Number.isInteger(Number(value)))
      )
      .transform((value: any) => (isNaN(value) ? undefined : value))
      .nullable(),
    socialSituation: Yup.string().required("يرجى إدخال الحالة المدنية"),
    levelScolaire: Yup.string().required("يرجى إدخال المستوى التعليمي"),
    profession: Yup.string().required("يرجى إدخال المهنة"),
    socialCoverage: Yup.string().required("يرجى اختيار التغطية الاجتماعية"),
    medicalCoverage: Yup.string().required("يرجى اختيار التغطية الصحية"),
  });

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(cases, {
        abortEarly: false,
      });

      await dispatch(addCases(cases)).then(() => {
        setIsLoading(false);
      });
      setCases({
        fullNameSource: "",
        status: "",
        email: "",
        phoneSource: 0,
        subject: "",
        fullNameConcerned: "",
        gender: "",
        birthday: "",
        city: "",
        address: "",
        phoneConcerned: 0,
        CIN: 0,
        socialSituation: "",
        levelScolaire: "",
        profession: "",
        socialCoverage: "",
        medicalCoverage: "",
        image: "",
      });

      setErrors({});
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: Partial<Record<keyof CaseInterface, string>> =
          {};
        err.inner.forEach((error: any) => {
          if (error.path) {
            validationErrors[error.path as keyof CaseInterface] = error.message;
          }
        });
        setErrors(validationErrors);
      }
    }
  };

  const handleSubmitSingle = async () => {
    try {
      const validationSchemaSibgle = Yup.object({
        fullNameSource: Yup.string().required("يرجى إدخال الاسم واللقب"),
        status: Yup.string().required("يرجى إدخال الصفة"),
        phoneSource: Yup.number()
          .test(
            "is-required",
            "يرجى إدخال رقم الهاتف",
            (value: any) => value !== undefined && value !== 0
          )
          .test(
            "is-valid-number",
            "رقم الهاتف غير صحيح",
            (value: any) =>
              !value ||
              (!isNaN(Number(value)) &&
                Number(value) > 0 &&
                Number.isInteger(Number(value)))
          )
          .transform((value: any) => (isNaN(value) ? undefined : value))
          .nullable(),
      });
      const fieldsToValidate = {
        fullNameSource: cases.fullNameSource,
        email: cases.email,
        status: cases.status,
        phoneSource: cases.phoneSource,
        image: cases.image,
      };

      await validationSchemaSibgle.validate(fieldsToValidate, {
        abortEarly: false,
      });

      await dispatch(addCases(cases)).then(() => {
        setIsLoading(false);
      });

      setErrors({});

      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: Partial<Record<keyof CaseInterface, string>> =
          {};
        err.inner.forEach((error: any) => {
          if (error.path) {
            validationErrors[error.path as keyof CaseInterface] = error.message;
          }
        });
        setErrors(validationErrors);
      }
    }
  };

  const steps: Step[] = [
    {
      title: (
        <div className="cdis--step-title">
          <span className="cdis--step-title-number">1</span>
          <span>المصدرالإحالة</span>
          {selectedComponent === "single" && current > 0 && (
            <img className="cdis--img-step" src={checked} alt="checked" />
          )}
        </div>
      ),
      content: (
        <FirstStep
          selectedComponent={selectedComponent}
          updateCases={(data) =>
            setCases({
              ...cases,
              fullNameSource: data.fullNameSource,
              status: data.status,
              email: data.email,
              phoneSource: data.phoneSource,
              image: data.image,
            })
          }
          data={{
            fullNameSource: cases.fullNameSource,
            status: cases.status,
            email: cases.email,
            phoneSource: cases.phoneSource,
            image: cases.image,
          }}
          setDisabled={setNextDisabled}
        />
      ),
    },
    {
      title: (
        <div className="cdis--step-title">
          <span className="cdis--step-title-number">2</span>
          <span>موضوع الإحالة</span>
          {current > 1 && (
            <img className="cdis--img-step" src={checked} alt="checked" />
          )}
        </div>
      ),
      content: (
        <>
          <div className="cdis--step-form cdis--error">
            <SelectLarge
              selectPlaceholder="أختر موضوع الإحالة"
              options={options}
              value={cases.subject || ""}
              onChange={(e) => {
                setCases({ ...cases, subject: e.target.value });
                setNextDisabled(false);
              }}
            />
            {errors.subject && (
              <span style={{ color: "red", marginTop: "-4rem" }}>
                {errors.subject}
              </span>
            )}
          </div>
        </>
      ),
      disabled: current < 1,
    },
    {
      title: (
        <div className="cdis--step-title">
          <span className="cdis--step-title-number">3</span>
          <span>المعني</span>
          {current > 2 && <img src={checked} alt="checked" />}
        </div>
      ),
      content: (
        <>
          <Row
            style={{ direction: "rtl" }}
            justify={"space-between"}
            gutter={48}
          >
            <Col
              xxl={10}
              xl={10}
              md={12}
              lg={12}
              xs={24}
              className="cdis--mt-3"
              style={{ direction: "rtl" }}
            >
              <InputWithoutIconExtralarge
                type="text"
                placeholder="الاسم واللقب"
                value={cases.fullNameConcerned || ""}
                onChange={(e) =>
                  setCases({ ...cases, fullNameConcerned: e.target.value })
                }
              />
              {errors.fullNameConcerned && (
                <span style={{ color: "red" }}>{errors.fullNameConcerned}</span>
              )}
            </Col>
            <Col
              xxl={5}
              xl={5}
              md={12}
              lg={12}
              xs={24}
              className="cdis--mt-3"
              style={{ direction: "rtl" }}
            >
              <SelectSmall
                selectPlaceholder="اختر الجنس"
                options={sexe}
                value={cases.gender || ""}
                onChange={(e) => setCases({ ...cases, gender: e.target.value })}
              />
              {errors.gender && (
                <span style={{ color: "red" }}>{errors.gender}</span>
              )}
            </Col>
            <Col
              xxl={5}
              xl={5}
              md={12}
              lg={12}
              xs={24}
              className="cdis--mt-3"
              style={{ direction: "rtl" }}
            >
              <InputWithoutIconNormal
                type="date"
                placeholder="18-09-1999"
                form="DD-MM-YYYY"
                value={cases.birthday || ""}
                onChange={(e) =>
                  setCases({ ...cases, birthday: e.target.value })
                }
              />
              {errors.birthday && (
                <span style={{ color: "red" }}>{errors.birthday}</span>
              )}
            </Col>

            <Col
              xxl={4}
              xl={4}
              md={12}
              lg={12}
              xs={24}
              className="cdis--mt-3"
              style={{ direction: "rtl" }}
            >
              <InputWithoutIconSmall
                type="text"
                placeholder="المدينة"
                value={cases.city || ""}
                onChange={(e) => setCases({ ...cases, city: e.target.value })}
              />
              {errors.city && (
                <span style={{ color: "red" }}>{errors.city}</span>
              )}
            </Col>
          </Row>
          {/* ------------------ */}
          <Row
            style={{ direction: "rtl" }}
            justify={"space-between"}
            gutter={48}
          >
            <Col
              xxl={7}
              xl={7}
              md={12}
              lg={12}
              xs={24}
              className="cdis--mt-3"
              style={{ direction: "rtl" }}
            >
              <InputWithoutIconExtralarge
                type="text"
                placeholder="العنوان"
                value={cases.address || ""}
                onChange={(e) =>
                  setCases({ ...cases, address: e.target.value })
                }
              />
              {errors.address && (
                <span style={{ color: "red" }}>{errors.address}</span>
              )}
            </Col>
            <Col
              xxl={5}
              xl={5}
              md={12}
              lg={12}
              xs={24}
              className="cdis--mt-3"
              style={{ direction: "rtl" }}
            >
              <InputWithoutIconMediumLarge
                type="number"
                placeholder="رقم الهاتف"
                value={cases.phoneConcerned || ""}
                onChange={(e) =>
                  setCases({ ...cases, phoneConcerned: Number(e.target.value) })
                }
              />
              {errors.phoneConcerned && (
                <span style={{ color: "red" }}>{errors.phoneConcerned}</span>
              )}
            </Col>
            <Col
              xxl={5}
              xl={5}
              md={12}
              lg={12}
              xs={24}
              className="cdis--mt-3"
              style={{ direction: "rtl" }}
            >
              <InputWithoutIconLarge
                type="number"
                placeholder="عدد ب ت و"
                value={cases.CIN || ""}
                onChange={(e) =>
                  setCases({ ...cases, CIN: Number(e.target.value) })
                }
              />
              {errors.CIN && <span style={{ color: "red" }}>{errors.CIN}</span>}
            </Col>

            <Col
              xxl={7}
              xl={7}
              md={12}
              lg={12}
              xs={24}
              className="cdis--mt-3"
              style={{ direction: "rtl" }}
            >
              <SelectMedium
                selectPlaceholder="التغطية الصحية "
                options={options3}
                value={cases.medicalCoverage || ""}
                onChange={(e) =>
                  setCases({ ...cases, medicalCoverage: e.target.value })
                }
              />
              {errors.medicalCoverage && (
                <span style={{ color: "red" }}>{errors.medicalCoverage}</span>
              )}
            </Col>
          </Row>
          {/* ------------------ */}
          <Row
            style={{ direction: "rtl" }}
            justify={"space-between"}
            gutter={48}
          >
            <Col
              xxl={5}
              xl={5}
              md={12}
              lg={12}
              xs={24}
              className="cdis--mt-3"
              style={{ direction: "rtl" }}
            >
              <InputWithoutIconMediumSmall
                type="text"
                placeholder="الحالة المدنية"
                value={cases.socialSituation || ""}
                onChange={(e) =>
                  setCases({ ...cases, socialSituation: e.target.value })
                }
              />
              {errors.socialSituation && (
                <span style={{ color: "red" }}>{errors.socialSituation}</span>
              )}
            </Col>
            <Col
              xxl={5}
              xl={5}
              md={12}
              lg={12}
              xs={24}
              className="cdis--mt-3"
              style={{ direction: "rtl" }}
            >
              <InputWithoutIconMedium
                type="text"
                placeholder="المستوى التعليمي"
                value={cases.levelScolaire || ""}
                onChange={(e) =>
                  setCases({ ...cases, levelScolaire: e.target.value })
                }
              />
              {errors.levelScolaire && (
                <span style={{ color: "red" }}>{errors.levelScolaire}</span>
              )}
            </Col>
            <Col
              xxl={7}
              xl={7}
              md={12}
              lg={12}
              xs={24}
              className="cdis--mt-3"
              style={{ direction: "rtl" }}
            >
              <InputWithoutIconMedium
                type="text"
                placeholder="المهنة"
                value={cases.profession || ""}
                onChange={(e) =>
                  setCases({ ...cases, profession: e.target.value })
                }
              />
              {errors.profession && (
                <span style={{ color: "red" }}>{errors.profession}</span>
              )}
            </Col>
            <Col
              xxl={7}
              xl={7}
              md={12}
              lg={12}
              xs={24}
              className="cdis--mt-3"
              style={{ direction: "rtl" }}
            >
              <SelectMedium
                selectPlaceholder="التغطية الإجتماعية"
                options={options2}
                value={cases.socialCoverage || ""}
                onChange={(e) =>
                  setCases({ ...cases, socialCoverage: e.target.value })
                }
              />
              {errors.socialCoverage && (
                <span style={{ color: "red" }}>{errors.socialCoverage}</span>
              )}
            </Col>
          </Row>
          {/* 
          
          <InputWithoutIconMediumSmall type="text" placeholder="الحالة المدنية" />
          <InputWithoutIconMedium type="text" placeholder="المستوى التعليمي" />
          <InputWithoutIconMedium type="text" placeholder="المهنة" />
          <SelectMedium selectPlaceholder="التغطية الإجتماعية" options={options} /> */}
        </>
      ),
      disabled: current < 2,
    },
  ];

  useEffect(() => {
    if (current === 2) {
      toVerifyData();
    }
  }, [cases, current]);

  const toVerifyData = async () => {
    try {
      await validationSchema.validate(cases, {
        abortEarly: false,
      });
      setErrors({});
      setNextDisabled(false);
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: Partial<Record<keyof CaseInterface, string>> =
          {};
        err.inner.forEach((error: any) => {
          if (error.path) {
            validationErrors[error.path as keyof CaseInterface] = error.message;
          }
        });
        setErrors(validationErrors);
        setNextDisabled(true);
      }
    }
  };

  const next: () => void = async () => {
    try {
      setCurrent(current + 1);
      setNextDisabled(true);
      setIsLoading(false);
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: Partial<Record<keyof CaseInterface, string>> =
          {};
        err.inner.forEach((error: any) => {
          if (error.path) {
            validationErrors[error.path as keyof CaseInterface] = error.message;
          }
        });
        setErrors(validationErrors);
      }
    }
  };

  const prev: () => void = () => {
    setCurrent(current - 1);
  };

  return (
    <div className="cdis--form-inscrit-container">
      <div className="cdis--form-img-header">
        <img
          width="30%"
          src={images}
          alt="logo-part"
          onClick={() => navigate("/")}
        />
        <img
          width="50%"
          src={logo}
          alt="logo-cdis"
          onClick={() => navigate("/")}
        />
      </div>

      <Row gutter={24} className="cdis--form-inscrit-body">
        <div className="cdis--form-inscrit-step">
          {selectedComponent && selectedComponent === "single" ? (
            <StepsComponent current={current} steps={steps} />
          ) : null}
          <div className="cdis--steps-content">{steps[current].content}</div>
        </div>
        <div className="cdis--form-inscrit-button">
          {selectedComponent && selectedComponent === "single" ? (
            <ButtonSecondary
              title="التالي"
              disabled={nextDisabled}
              onClick={() => {
                if (current < steps.length - 1) {
                  setIsLoading(true);
                  setTimeout(() => {
                    next();
                  }, 1000);
                } else {
                  setIsLoading(true);
                  setTimeout(() => {
                    handleSubmit();
                  }, 1000);
                }
              }}
              loading={isLoading}
            />
          ) : (
            <ButtonSecondary
              title="التالي"
              disabled={nextDisabled}
              onClick={() => {
                handleSubmitSingle();
                setIsLoading(true);
              }}
              loading={isLoading}
            />
          )}

          {current > 0 && (
            <ButtonPrimary
              title="السابق"
              onClick={() => {
                prev();
              }}
              disabled={current === 0}
            />
          )}
        </div>
      </Row>
    </div>
  );
};

export default FormInscrit;
