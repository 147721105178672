import React from "react";
import "../../styles/localisationSection.scss";
import localIcon from "../../assets/images/locateIcon.svg";
import TextParagraph from "../../Biblio/Text/TextParagraph";
import MapComponent from "../map/MapComponent";
const LocalisationSection = () => {
  return (
    <section className="cdis--localisation-section-container">
      <div className="cdis-licalisation-image">
        <MapComponent />
      </div>
      <div className="cdis-localisation-description">
        <img alt="icon" src={localIcon} />
        <h5 className="cdis-localisation-title">تعرّف على مكان مركزنا</h5>
        <TextParagraph title="حي الأمل الرابع , قابس , تونس" />
        <TextParagraph title="قابس الجنوبية " />
      </div>
    </section>
  );
};

export default LocalisationSection;
