import React, { useEffect, useState } from "react";
import "../../styles/login.scss";
import logo from "../../assets/logos/cedis-big.svg";
import usernameIcon from "../../assets/icons/username.svg";
import passwordIcon from "../../assets/icons/password.svg";
import image from "../../assets/images/login-img.svg";
import images from "../../assets/images/partner-3.svg";
import TextPrimary from "../../Biblio/Text/TextPrimary";
import TextSecondary from "../../Biblio/Text/TextSecondary";
import InputWithIcon from "../../Biblio/Inputs/InputWithIcon";
import ButtonTextBig from "../../Biblio/Buttons/ButtonTextBig";
import { Col, message, Row } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../proxy";
import { useDispatch } from "react-redux";
import {
  checkUserConnection,
  loginUser,
} from "../../apis/actions/auth.actions";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../apis/store";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { SectionLoader } from "../../Biblio/loaders/SectionLoader";

export interface UserLogin {
  email: string;
  password: string;
}

const Login = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [captchaValidated, setIsCaptchaValidated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userLogin, setUserLogin] = useState<UserLogin>({
    email: "",
    password: "",
  });

  const handleLogin = async () => {
    try {
      const loginResult = await dispatch(loginUser(userLogin)).unwrap();
      setLoading(false);
      if (loginResult) {
        const checkUserResult = await dispatch(checkUserConnection()).unwrap();

        if (checkUserResult) {
          navigate("/sidebar");
        }
      }
      message.success("تم تسجيل الدخول بنجاح");
    } catch (error) {
      message.error("البريد الالكتروني او كلمة المرور غير صحيحة");
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setIsLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      {isLoading && (
        <div className="cdis--loader-container">
          <SectionLoader />
        </div>
      )}

      <div className="cdis--login-container">
        <Row justify="space-between" align="middle" gutter={48}>
          <Col xxl={6} xl={6} md={12} lg={12} xs={24}>
            <div className="cdis--login-header">
              <img
                width="40%"
                src={images}
                alt="logo"
                onClick={() => navigate("/")}
              />
              <img
                width="80%"
                src={logo}
                alt="logo"
                onClick={() => navigate("/")}
              />
            </div>
          </Col>
          <Col xxl={12} xl={12} md={12} lg={12} xs={24}>
            <div className="cdis--login-header-text">
              <TextPrimary title="مركز الدفاع والادماج الاجتماعي بقابس" />
              <TextSecondary title="تسجيل الدخول" />
            </div>
          </Col>
        </Row>

        <Row justify="space-between" gutter={24}>
          <Col xxl={12} xl={12} md={12} lg={12} xs={24}>
            <div className="cdis--login-img-container">
              <img src={image} alt="login-img" />
            </div>
          </Col>
          <Col xxl={12} xl={12} md={12} lg={12} xs={24}>
            <div className="cdis--login-form-container">
              <div>
                <InputWithIcon
                  type="text"
                  placeholder="إسم المستخدم"
                  icon={usernameIcon}
                  onChange={(e) => {
                    setUserLogin({ ...userLogin, email: e.target.value });
                  }}
                />
              </div>
              <div className="cdis--login-input-password">
                <InputWithIcon
                  type={passwordVisible ? "text" : "password"}
                  placeholder="كلمة السر"
                  icon={passwordIcon}
                  onChange={(e) => {
                    setUserLogin({ ...userLogin, password: e.target.value });
                  }}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="cdis--login-password-visibility-btn"
                >
                  {passwordVisible ? (
                    <EyeOutlined className="cdis--login-password-visibility" />
                  ) : (
                    <EyeInvisibleOutlined className="cdis--login-password-invisibility" />
                  )}
                </button>
              </div>
              <div className="cdis--captcha-container">
                <ReCAPTCHA
                  sitekey={recaptchaKey ?? ""}
                  onChange={(c) =>
                    c
                      ? setIsCaptchaValidated(true)
                      : setIsCaptchaValidated(false)
                  }
                  hl="ar"
                />
              </div>

              <div>
                <ButtonTextBig
                  disabled={!captchaValidated}
                  title="تسجيل الدخول"
                  onClick={() => {
                    setLoading(true);
                    handleLogin();
                  }}
                  loading={loading}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Login;
