import React, { ChangeEvent, useEffect, useState } from "react";
import SearchInput from "../../Biblio/SearchInput";
import { options } from "../../data/data";
import "../../styles/perspectives.scss";

import SelectItem from "../../Biblio/Selects/SelectItem";
import PaginationTable from "../../Biblio/PaginationTable";
import { Table } from "antd";
import DetailsModal from "./modals/DetailsModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../apis/store";
import {
  deleteCases,
  getAllCases,
  getAllCasesTrue,
  updateCases,
} from "../../apis/actions/case.actions";
import { CaseInterface } from "../../interfaces/case.interface";
import DashboardButtonOpportunities from "../../Biblio/Buttons/DashboardButtonOpportunities";
import ButtonPrimary from "../../Biblio/Buttons/ButtonPrimary";
import { SectionLoader } from "../../Biblio/loaders/SectionLoader";

const Perspectives = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<
    ChangeEvent<HTMLSelectElement> | string
  >("");
  const [currentPageTrue, setCurrentPageTrue] = useState<number>(1);
  const [searchTermTrue, setSearchTermTrue] = useState<string>("");
  const [activeButton, setActiveButton] = useState<string>("المنظورين");

  const data = useSelector((state: any) => state?.cases?.cases?.data?.success);
  const totalPages = useSelector(
    (state: any) => state?.cases?.cases?.data?.totalCases
  );
  const dataTrue = useSelector(
    (state: any) => state?.cases?.casesTrue?.data?.success
  );

  const totalPagesTrue = useSelector(
    (state: any) => state?.cases?.casesTrue?.data?.totalCases
  );

  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(
        getAllCasesTrue({
          page: currentPageTrue,
          search: searchTermTrue,
          selectSearch: selectedOption,
        })
      );
      await dispatch(
        getAllCases({
          page: currentPage,
          search: searchTerm,
          selectSearch: selectedOption,
        })
      );
      setLoading(false);
    };
    fetchData();
  }, [
    dispatch,
    currentPageTrue,
    searchTermTrue,
    currentPage,
    searchTerm,
    selectedOption,
  ]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };
  const handlePageChangeTrue = (page: number) => {
    setCurrentPageTrue(page);
  };

  const handleSearchTrue = (value: string) => {
    setSearchTermTrue(value);
  };
  const handleSearchSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUpdate = async (data: any, newBooleanValue: boolean) => {
    const updatedData = { ...data, isDraft: newBooleanValue };
    setIsLoading(true);

    try {
      await dispatch(updateCases({ id: data.id, cases: updatedData }));
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await dispatch(
        getAllCasesTrue({
          page: currentPageTrue,
          search: searchTermTrue,
          selectSearch: selectedOption,
        })
      );
      await dispatch(
        getAllCases({
          page: currentPage,
          search: searchTerm,
          selectSearch: selectedOption,
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handledelete = (data: any) => {
    try {
      dispatch(deleteCases({ id: data.source.id })).then(() => {
        dispatch(
          getAllCasesTrue({
            page: currentPageTrue,
            search: searchTermTrue,
            selectSearch: selectedOption,
          })
        );
      });
    } catch (error) {
      console.error("Failed to update case:", error);
    }
  };

  const dataSource =
    data &&
    data.map((item: any, index: number) => ({
      id: item.id,
      fullNameConcerned: item.fullNameConcerned,
      phoneConcerned: item.phoneConcerned,
      CIN: item.CIN,
      subject: item.subject,
      levelScolaire: item.levelScolaire,
      socialCoverage: item.socialCoverage,
      medicalCoverage: item.medicalCoverage,
      source: item.source,
    }));

  const dataSourceTrue =
    dataTrue &&
    dataTrue.map((item: any, index: number) => ({
      id: item.id,
      fullNameConcerned: item.fullNameConcerned,
      phoneConcerned: item.phoneConcerned,
      CIN: item.CIN,
      subject: item.subject,
      levelScolaire: item.levelScolaire,
      isDraft: item.isDraft,
      socialCoverage: item.socialCoverage,
      medicalCoverage: item.medicalCoverage,
      source: item.source,
    }));

  const columns = [
    {
      title: "الاسم واللقب",
      dataIndex: "fullNameConcerned",
      key: "fullNameConcerned",
      width: "15%",
    },
    {
      title: "عدد ب ت و",
      dataIndex: "CIN",
      key: "CIN",
      width: "15%",
    },
    {
      title: "رقم الهاتف",
      dataIndex: "phoneConcerned",
      key: "phoneConcerned",
      width: "15%",
    },
    {
      title: "موضوع الإحالة",
      dataIndex: "subject",
      key: "subject",
      width: "15%",
    },
    {
      title: "المستوى التعليمي",
      dataIndex: "levelScolaire",
      key: "levelScolaire",
      width: "15%",
    },
    {
      title: "التغطية الإجتماعية",
      dataIndex: "socialCoverage",
      key: "socialCoverage",
      width: "15%",
    },
    {
      title: "المزيد",
      dataIndex: "more",
      key: "more",
      width: "10%",
      render: (text: any, record: CaseInterface) => (
        <DetailsModal data={record} />
      ),
    },
  ];

  const columnsTrue = [
    {
      title: "الاسم واللقب",
      dataIndex: "fullNameConcerned",
      key: "fullNameConcerned",
      width: "15%",
    },
    {
      title: "عدد ب ت و",
      dataIndex: "CIN",
      key: "CIN",
      width: "15%",
    },
    {
      title: "رقم الهاتف",
      dataIndex: "phoneConcerned",
      key: "phoneConcerned",
      width: "10%",
    },
    {
      title: "موضوع الإحالة",
      dataIndex: "subject",
      key: "subject",
      width: "15%",
    },
    {
      title: "المستوى التعليمي",
      dataIndex: "levelScolaire",
      key: "levelScolaire",
      width: "20%",
    },
    {
      title: "التغطية الإجتماعية",
      dataIndex: "socialCoverage",
      key: "socialCoverage",
      width: "20%",
    },
    {
      title: "المزيد",
      dataIndex: "more",
      key: "more",
      width: "10%",
      render: (text: any, record: CaseInterface) => (
        <DetailsModal data={record} />
      ),
    },
    {
      title: "الموافقة",
      dataIndex: "more",
      key: "more",
      width: "10%",
      render: (text: any, record: any) => (
        <span className="cdis--btn-perspective-container">
          <ButtonPrimary
            className="cdis--btn-perspective"
            title="موافقة"
            onClick={() => {
              setIsLoading(true);
              handleUpdate(record, false);
            }}
            loading={isLoading}
          />
          <ButtonPrimary
            className="cdis--btn-perspective-2"
            title="إلغاء"
            onClick={() => {
              handledelete(record);
            }}
          />
        </span>
      ),
    },
  ];

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
  };

  return (
    <div className="cdis--perspectives-container">
      <div className="cdis--perspectives-header">
        <h1 className="cdis--perspectives-title">المنظورين</h1>
        <div className="cdis--perspectives-box">
          <SearchInput placeholder="بحث" onSearch={handleSearch} />
          <SelectItem
            options={options}
            selectPlaceholder="أختر موضوع الإحالة"
            onChange={handleSearchSelect}
          />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
          marginTop: "1rem",
        }}
        className="cdis--opportunities-buttons"
      >
        <DashboardButtonOpportunities
          isActive={activeButton === "المنظورين"}
          onClick={() => handleButtonClick("المنظورين")}
          title="المنظورين"
        />
        <DashboardButtonOpportunities
          title={`(${dataTrue?.length})  قائمة الإنتظار`}
          className="cdis--button-opportunities-2"
          isActive={activeButton === "قائمة الإنتظار"}
          onClick={() => handleButtonClick("قائمة الإنتظار")}
        />
      </div>

      {activeButton === "المنظورين" ? (
        <div className="cdis--perspectives-box-container">
          <div className="cdis--perspectives-table">
            {loading ? (
              <div className="cdis--loader-container">
                <SectionLoader />
              </div>
            ) : (
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
            )}
          </div>

          <PaginationTable
            defaultCurrent={1}
            current={currentPage}
            total={totalPages}
            onChange={handlePageChange}
          />
        </div>
      ) : (
        <div className="cdis--perspectives-box-container">
          <div className="cdis--perspectives-table">
            {loading ? (
              <div className="cdis--loader-container">
                <SectionLoader />
              </div>
            ) : (
              <Table
                dataSource={dataSourceTrue}
                columns={columnsTrue}
                pagination={false}
              />
            )}
          </div>
          <PaginationTable
            defaultCurrent={1}
            current={currentPageTrue}
            total={totalPagesTrue}
            onChange={handlePageChangeTrue}
          />
        </div>
      )}
    </div>
  );
};

export default Perspectives;
