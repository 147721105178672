import React, { useEffect, useState } from "react";
import "../../styles/opportunities.scss";
import { Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../apis/store";
import AddNewsModal from "./modals/AddNewsModal";
import NewDetailsCard from "../../Biblio/cards/NewDetailsCard";
import { New } from "../../interfaces/new.types";
import { getAllNews } from "../../apis/actions/news.actions";
const News = () => {
  const dispatch = useDispatch<AppDispatch>();
  const news = useSelector((state: RootState) => state?.news?.news);
  useEffect(() => {
    const fetchData = async () => {
      dispatch(getAllNews());
    };
    fetchData();
  }, [dispatch]);

  return (
    <div className="cdis--dashboard-opportunities-container">
      <div className="cdis--dashboard-opportunities-header">
        <h1 className="cdis--opportunities-title">المستجدات</h1>
        <AddNewsModal />
      </div>
      <div
        className={
          news?.length > 0
            ? "cdis--opportunities-cards-container"
            : "cdis--no-message-container"
        }
      >
        {news?.length > 0 ? (
          news.map((annonce, index: number) => (
            <React.Fragment key={index}>
              <NewDetailsCard annonce={annonce} />
              {index < news.length - 1 && (
                <Divider className="cdis--divider-opportunities" />
              )}
            </React.Fragment>
          ))
        ) : (
          <div className="cdis--no-news-message">لا توجد مستجدات حالياً</div>
        )}
      </div>
    </div>
  );
};

export default News;
