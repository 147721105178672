import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import { New } from "../../interfaces/new.types";
import api from "../utils/interceptor-expire-token";

export const addNew = createAsyncThunk("news/addNew", async (annonce: any) => {
  try {
    const response = await api.post(`annonce/add-annonce`, annonce);
    message.success("تمت إضافة المستجد");
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      message.error(error.response?.data?.message || "An error occurred");
    } else {
      message.error("An unexpected error occurred");
    }
    console.log(error);
    throw error;
  }
});
//// Get all News
export const getAllNews = createAsyncThunk(
  "annonces/get-annonces",
  async () => {
    try {
      const response = await api.get(`annonce/get-annonces`);
      return response?.data?.success;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || "An error occurred");
      } else {
        message.error("An unexpected error occurred");
      }
      console.log(error);
      throw error;
    }
  }
);
//Update New
export const updateNew = createAsyncThunk(
  "news/updateNew",
  async ({ id, annonce }: { id: string; annonce: New }) => {
    try {
      const response = await api.post(
        `annonce/update-annonce?id=${id}`,
        annonce
      );
      message.success("تم تحديث المستجد");

      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || "An error occurred");
      } else {
        message.error("An unexpected error occurred");
      }
      console.error(error);
      throw error;
    }
  }
);
//Delete New with ID
export const deleteNew = createAsyncThunk(
  "news/deleteNew",
  async (id: string) => {
    try {
      await api.delete(`annonce/delete-annonce?id=${id}`);
      message.success("تم حذف المستجد");
      return id;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || "An error occurred");
      } else {
        message.error("An unexpected error occurred");
      }
      console.log(error);
      throw error;
    }
  }
);
// Get One New by ID
export const getOneNewById = createAsyncThunk(
  "news/getOneNewById",
  async (id: string) => {
    try {
      const response = await api.get(`annonce/{id}?id=${id}`);
      return response?.data?.success;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || "An error occurred");
      } else {
        message.error("An unexpected error occurred");
      }
      console.log(error);
      throw error;
    }
  }
);
