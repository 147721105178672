import React from "react";
import "../../styles/buttons.scss";
import ButtonLoader from "../loaders/ButtonLoader";

interface ButtonTextBigProps {
  onClick: () => void;
  title: string;
  disabled?: boolean;
  loading?: boolean;
}

const ButtonTextBig = ({
  title,
  loading,
  onClick,
  disabled,
}: ButtonTextBigProps) => {
  return (
    <button
      className="cdis--button-text-big"
      onClick={onClick}
      disabled={disabled}
    >
      {title}
      {loading ? <ButtonLoader />: <div style={{ width: '20px', padding: '8px' }} />}
    </button>
  );
};

export default ButtonTextBig;
