import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { Opportunity } from "../../interfaces/opportunity.types";
import api from "../utils/interceptor-expire-token";

export const addOpportunity = createAsyncThunk(
  "opportunities/addOpportunity",
  async (opportunity: any) => {
    try {
      const response = await api.post(
        `opportunities/add-opportunity`,
        opportunity
      );
      message.success("تمت إضافة الفرصة");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || "An error occurred");
      } else {
        message.error("An unexpected error occurred");
      }
      console.log(error);
      throw error;
    }
  }
);
//Get all Opportunities
export const getAllOpportunities = createAsyncThunk(
  "opportunities/getAllOpportunities",
  async (type: string) => {
    try {
      const response = await api.get(`opportunities/all-opportunities`, {
        params: { type },
      });
      return response?.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || "An error occurred");
      } else {
        message.error("An unexpected error occurred");
      }
      console.log(error);
      throw error;
    }
  }
);
//// Get all Opportunities for landing page section
export const getAllOpportunitiesPublic = createAsyncThunk(
  "opportunities/getAllOpportunitiesPublic",
  async () => {
    try {
      const response = await api.get(`opportunities/all-opportunities-public`);
      return response?.data?.success;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || "An error occurred");
      } else {
        message.error("An unexpected error occurred");
      }
      console.log(error);
      throw error;
    }
  }
);
//Delete Opportunity with ID
export const deleteOpportunity = createAsyncThunk(
  "opportunities/deleteOpportunity",
  async (id: string) => {
    try {
      await axios.delete(`opportunities/delete-opportunity?id=${id}`);
      message.success("تم حذف الفرصة");
      return id; // Return the ID of the deleted opportunity
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || "An error occurred");
      } else {
        message.error("An unexpected error occurred");
      }
      console.log(error);
      throw error;
    }
  }
);

//Update Opportunity
export const updateOpportunity = createAsyncThunk(
  "opportunities/updateOpportunity",
  async ({ id, opportunity }: { id: string; opportunity: Opportunity }) => {
    try {
      const response = await api.post(`opportunities/${id}`, opportunity);
      message.success("تم تحديث الفرصة");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || "An error occurred");
      } else {
        message.error("An unexpected error occurred");
      }
      console.error(error);
      throw error;
    }
  }
);
