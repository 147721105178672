import React, { useState } from "react";
import { Card, Row, Col } from "antd";
import FormInscrit from "./FormInscrit";
import TextPrimary from "../../Biblio/Text/TextPrimary";
import logo from "../../assets/logos/cedis-xsmall.svg";
import images from "../../assets/images/partner-3.svg";
import { useNavigate } from "react-router-dom";
import "../../styles/formInscrit.scss";

const HomePage = () => {
  const navigate = useNavigate();
  const [selectedComponent, setSelectedComponent] = useState<string>("");

  const renderComponent = () => {
    switch (selectedComponent) {
      case "single":
        return <FormInscrit selectedComponent={selectedComponent} />;
      case "multiple":
        return <FormInscrit selectedComponent={selectedComponent} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={!selectedComponent ? `cdis--form-inscrit-container-home` : ""}
    >
      <div className="cdis--form-img-header" onClick={() => navigate("/")}>
        {!selectedComponent ? (
          <img width="30%" src={images} alt="logo-part" />
        ) : null}
        {!selectedComponent ? (
          <img width="50%" src={logo} alt="logo-cdis" />
        ) : null}
      </div>

      {!selectedComponent && (
        <Row gutter={16} justify="center" style={{ width: "100%" }}>
          <Col
            xs={24}
            sm={18}
            md={12}
            lg={10}
            className="cdis--form-inscrit-home-page-card"
          >
            <Card
              onClick={() => setSelectedComponent("single")}
              className="cdis--form-inscrit-home-page-card-2"
              bordered
              hoverable
            >
              <TextPrimary className="cdis--text-card" title="إضافة حالة" />
            </Card>
          </Col>
          <Col
            xs={24}
            sm={18}
            md={12}
            lg={10}
            className="cdis--form-inscrit-home-page-card"
          >
            <Card
              onClick={() => setSelectedComponent("multiple")}
              hoverable
              className="cdis--form-inscrit-home-page-card-1"
              bordered
            >
              <TextPrimary
                className="cdis--text-card"
                title="إضافة عدة حالات (تحميل ملف)"
              />
            </Card>
          </Col>
        </Row>
      )}
      {renderComponent()}
    </div>
  );
};

export default HomePage;
